import imageClose from 'images/login/login_icon_ojocerrado.svg';
import imageOpen from 'images/login/login_icon_ojo.svg';

$(document).ready(() => {
  $(document).on('click', ".input-group .reveal", (e) => {
    e.stopPropagation();
    e.stopImmediatePropagation();

    let current = $(e.currentTarget);
    let $pwd = current.parents(".input-group").children(".form-control");
    if ($pwd.attr('type') === 'password') {
      $pwd.attr('type', 'text');
      current.children("img").attr('src', imageClose);
    } else {
      $pwd.attr('type', 'password');
      current.children("img").attr('src', imageOpen);
    }
  });

  function setRandomPassword(userpassword,passwordconfirmation) {
    var randomstring = Math.random().toString(36).slice(-6);
    randomstring = randomstring + randomstring.charAt(0).toUpperCase() + '1$#';
    $('#'+userpassword).val(randomstring);
    $('#'+passwordconfirmation).val(randomstring);
    }

  $(document).on('click',"#generatePasswordEdit",(e)=>{   
    setRandomPassword('user_password','user_password_confirmation');
  });
  $(document).on('click',"#generatePassword",(e)=>{
    setRandomPassword('password','passwordconfirmation');
  });

  $(document).on('change',"#user_password",(e)=>{
    if($("#user_password_confirmation").attr('type') == 'hidden'){
      var passwordstring = $('#user_password').val();
      $('#user_password_confirmation').val(passwordstring);
    }
    
  });
  $(document).on('change',"#password",(e)=>{
    if($("#password_confirmation").attr('type') == 'hidden'){
      var passwordstring = $('#password').val();
      $('#password_confirmation').val(passwordstring);
    }
  });
  
});