$(document).ready(() => {
  $("#lost_password").click((d) => {
    d.preventDefault();
    $("#recover_alert").show();
    $("#recover_alert_bkg").show();
    $(document).scrollTop(0);
    $(".devise").css({
      overflow: "hidden",
      height: "100%"
    });
  })

  function hideAlert() {
    $("#recover_alert").hide();
    $("#recover_alert_bkg").hide();
    $(".devise").css({
      overflow: "",
      height: ""
    });
  }

  $("#recover_alert .btn-cancel").click((d) => {
    d.preventDefault();
    hideAlert();
  });

  $("#recover_alert_bkg").click(() => hideAlert());

});
